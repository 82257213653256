<template>
  <div class="content">
    <div class="text-center" v-if="isLoading">
      <Loading />
    </div>
    <div class="col-12 containerimg1">
      <img src="@/assets/wallstreet/term-01.png" width="100%" />

      <div
        class="centered size font-input text-modal-terms"
        v-html="data.term_and_condition"
      ></div>
      <span class="centered1 size font-input" @click="goTo(`/register`)">
        ตกลง
      </span>
    </div>
    <div class="container col-12">
      <img
        class="img top-footer"
        src="@/assets/wallstreet/Footer.png"
        alt=""
        srcset=""
      />
    </div>
  </div>
</template>


<script>
import { HTTP } from "../utility/http";
import Loading from "../components/Loading";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      is_tab: true,
      d: "<",
      data: "",
      isLoading: true,
    };
  },
  mounted() {
    localStorage.removeItem("otp");
    this.getAll();
    if (this.tab === 1) {
      this.is_tab = false;
    } else {
      this.is_tab = true;
    }
    this.isLoading = false;
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
    },
    changeTab() {
      this.is_tab = !this.is_tab;
    },
    async getAll() {
      const JSONRegister = {
        sort: "",
        order: "",
        limit: 10,
        offset: 0,
        is_export: false,
        filter: {
          start_date: "0001-01-01T00:00:00Z",
          end_date: "0001-01-01T00:00:00Z",
        },
      };
      const { data, status } = await HTTP.post(
        `/cms/api/term-and-condition/list`,
        JSONRegister
      );
      if (status === 200) {
        this.data = {
          ...data.rows[0],
        };
      }
    },
  },
};
</script>
<style scoped>
span:hover {
  color: #093359;
}
.content {
  padding: 0px;
  width: 100%;
  max-width: 575px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url(../assets/wallstreet/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.size {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .size {
    font-size: calc(17px + 6 * ((100vw - 320px) / 680));
  }
  .otp-input {
    width: 30px;
    height: 30px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(300px);
    width: calc(250px);
    overflow: auto;
  }
}
@media screen and (min-width: 375px) {
  .size {
    font-size: calc(19px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 40px;
    height: 40px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(350px);
    width: calc(300px);
    overflow: auto;
  }
}
@media screen and (min-width: 425px) {
  .size {
    font-size: calc(21px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(406px);
    width: calc(313px);
    overflow: auto;
  }
}

@media screen and (min-width: 576px) {
  .size {
    font-size: calc(22px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(520px);
    width: calc(430px);
    overflow: auto;
  }
}
@media screen and (min-width: 1000px) {
  .size {
    font-size: 22px;
  }
  .otp-input {
    width: 55px;
    height: 55px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .size-pass {
    font-size: "1rem";
  }
  .text-modal-terms {
    height: calc(545px);
    width: calc(430px);
    overflow: auto;
  }
}
.containerimg1 {
  position: relative;
  /* text-align: center; */
  color: white;
}
.centered {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered1 {
  position: absolute;
  top: 94%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mt-term {
  margin-top: -1000px;
}
.img {
  width: 100%;
}
.point-button {
  margin-left: 121px;
  margin-top: 120px;
}
.img {
  width: 100%;
}
.image-register {
  position: fixed;
  width: 100%;
  height: 886px;
  bottom: 0px;
  right: 0px;
  background-repeat: no-repeat;
}
.rcorners {
  border-radius: 25px;
  background: #73ad21;
  padding: 20px;
  width: 200px;
  height: 150px;
}
.botton {
  margin-top: -730px;
}
.input-bottom {
  margin-bottom: 0rem;
}
.top-modal {
  margin-top: -490px;
}
</style>