<template>
  <div class="content">
    <div class="box">
      <div class="box-header"></div>
      <div class="profile mx-auto mb-3 containerimg">
        <img src="@/assets/wallstreet/pass_full01.png" width="100%" />
        <div class="centered size font-input">กรุณาระบุหมายเลย OTP</div>
        <div class="centered0 size-logo font-input">
          รหัสผ่านแบบใช้ครั้งเดียว
        </div>
        <div class="centered01 size-logo font-input">จาก SMS (OTP)</div>
        <div class="centered-box size font-input" style="color: #003359">
          <span> ที่ส่งไปยังหมายเลข</span>
        </div>
        <div class="centered-box1 size font-input" style="color: #003359">
          <span>{{ tel01 }}-XXX-{{ tel03 }}</span>
        </div>
        <div class="centered-box2 size font-input" style="color: #003359">
          <span> รหัสจะหมดอายุภายใน 5 นาที</span>
        </div>

        <div class="centered-box3 font-input" style="color: #003359">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            :num-inputs="6"
            :should-auto-focus="focus"
            :is-input-num="true"
            @on-complete="handleOnComplete"
            v-model="otpnum"
          />
        </div>

        <div class="centered-box4 font-input" style="color: #fff">
          <span v-if="errors.length">
            <span class="size-pass" style="color: #e34229">{{ errpass }}</span>
          </span>
        </div>

        <div class="container col-12 centered-box8 font-input">
          <label
            for="empid"
            style="color: #fff"
            class="font-input"
            @click="submit(value)"
            v-if="reNew === true"
          >
            <p class="size-time">
              <span style="color: #fff"> {{ changpass }}</span>
            </p>
          </label>

          <label for="empid" style="color: #fff" class="font-input" v-else>
            <p class="size-time">
              <span
                style="color: #fff"
                v-if="timer === ``"
                @click="submit(value)"
              >
                {{ changpass }}{{ " " }}{{ timer }}</span
              >
              <span style="color: #fff" v-else>
                {{ changpass }}{{ " " }}{{ timer }}</span
              >
            </p>
          </label>
        </div>

        <!-- <div class="size font-input" style="color: #fff">
			<label
			for="empid"
			style="color: #fff"
			class="font-input centered-box8"
			@click="submit(value)"
			v-if="reNew === true"
			>
			<p>
				<b> {{ changpass }}</b>
			</p>
			</label>
			<label
			for="empid"
			style="color: #fff"
			class="font-input centered-box5"
			v-else
			>
			<p>
				<b v-if="timer === ``" @click="submit(value)">
				{{ changpass }}{{ " " }}{{ timer }}</b
				>
				<b v-else> {{ changpass }}{{ " " }}{{ timer }}</b>
			</p>
			</label>
		</div> -->

        <div class="centered-box6 size font-input" style="color: #fff">
          <div style="color: #fff" @click="verifyCode()">
            <span>ถัดไป</span>
          </div>
        </div>
      </div>
      <img src="@/assets/wallstreet/Footer.png" width="100%" />
      <div class="container" style="display: none" id="log-in"></div>
    </div>
  </div>
</template>



<script>
import OtpInput from "../otp/OtpInput.vue";
import "firebase/auth";
import firebase from "firebase";
import { HTTP } from "../../utility/http";
import qs from "qs";

import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_API_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// console.log()
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default {
  components: {
    "v-otp-input": OtpInput,
  },
  data() {
    return {
      pass: "",
      changpass: "ขอรหัสผ่านใหม่อีกครั้ง",
      errpass: "รหัสผ่านแบบใช้ครั้งเดียวไม่ถูกต้อง",
      timer: "00:00",
      interval: "",
      reNew: true,
      value: true,
      phoneNumber: null,
      confirmationResult: null,
      otpnum: null,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      confirmResult: null,
      smsSent: false,
      otp: "",
      tel: " ",
      tel01: " ",
      tel02: " ",
      tel03: " ",
      focus: true,
      errors: [],
    };
  },
  async mounted() {
    const otp = localStorage.getItem("otp");
    if (otp === "true") {
      this.goTo(`/connected`);
    } else {
      const register = localStorage.getItem("payload_register");
      const JSONRegister = JSON.parse(register);

      this.tel = JSONRegister.phone_number;
      this.tel01 = this.tel.substring(0, 3);
      this.tel02 = this.tel.substring(3, 6);
      this.tel03 = this.tel.substring(6, 10);

      firebase.auth().useDeviceLanguage();
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("log-in", {
        size: "invisible",
        callback: () => {},
      });
      this.reNew = true;
      this.submitA();
    }
  },
  methods: {
    Focus() {
      document.getElementById("myText").focus();
    },
    goTo(link) {
      this.$router.push(link);
    },
    async closrliff() {
      //liff close
      // eslint-disable-next-line no-undef
      await liff.closeWindow();
    },
    handleOnComplete(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    async submitFinal() {
      const register = localStorage.getItem("payload_register");
      const JSONRegister = JSON.parse(register);
      if (JSONRegister.branch === `Alumni`) {
        const { data, status } = await HTTP.post(
          `api/register/alumni`,
          JSONRegister
        );
        if (status === 200) {
          if (data.status === "CREATE_SUCCESS") {
            this.goTo(`/connected`);
          } else {
            this.error = data.status;
          }
        }
      } else if (JSONRegister.branch === `Nonstudent`) {
        const { data, status } = await HTTP.post(
          `/api/register/non/student`,
          JSONRegister
        );

        if (status === 200) {
          if (data.status === "CREATE_SUCCESS") {
            this.goTo(`/connected`);
          } else {
            this.error = data.status;
          }
        }
      } else {
        const { data, status } = await HTTP.post(
          `api/register/new`,
          JSONRegister
        );
        if (status === 200) {
          if (data.status === "CREATE_SUCCESS") {
            this.goTo(`/connected`);
          } else {
            this.error = data.status;
          }
        }
      }
    },
    async submit(value) {
      const register = localStorage.getItem("payload_register");
      const JSONRegister = JSON.parse(register);
      this.tel = JSONRegister.phone_number;
      var phoneNumber = "+66" + this.tel.substring(1, 10);
      const appVerifier = this.recaptchaVerifier;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          this.confirmResult = confirmationResult;
          // console.log("Sms Sent!");
          this.smsSent = true;
          this.focus = true;
          if (value === true) {
            this.reNew = false;
            this.changName();
            this.countDown(300);
          } else {
            console.log("err");
            this.changpass = "ขอรหัสผ่านใหม่อีกครั้ง";
            this.reNew = false;
          }
        })
        .catch((error) => {
          this.focus = true;
          console.log("Sms not sent", error.message);
        });
    },
    async submitA() {
      const register = localStorage.getItem("payload_register");
      const JSONRegister = JSON.parse(register);
      this.tel = JSONRegister.phone_number;
      var phoneNumber = "+66" + this.tel.substring(1, 10);
      const appVerifier = this.recaptchaVerifier;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          this.confirmResult = confirmationResult;
          this.smsSent = true;
          this.focus = true;
        })
        .catch((error) => {
          console.log(error.code)
          this.focus = true;
          let payload = { message: error.message + `,` +error.code };
          const { data, status } =  HTTP.post(
            `api/register/app/log`,
            payload
          );
          console.log("Sms not sent", error.message,",",error.code);
          
        });
    },
    verifyCode() {
      this.confirmResult
        .confirm(this.otp)
        .then(() => {
          // result
          // console.log("Registeration Successfull!", result);
          this.submitFinal();
        })
        .catch((error) => {
          this.errors.push("pass required.");
          console.log(error);
        });
    },
    gotonext() {
      this.goTo(`/connected`);
    },
    changName() {
      this.changpass = "ขอรหัสผ่านอีกครั้งภายใน";
    },
    countDown(duration) {
      let timer = duration,
        minutes,
        seconds;
      this.interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timer = minutes + ":" + seconds;
        this.reNew = false;

        if (--timer < 0) {
          timer = duration;
          clearInterval(this.interval);
          this.reNew === true;
          this.changpass = "ขอรหัสผ่านใหม่อีกครั้ง";
          this.timer = "";
        }
      }, 1000);
    },
  },
};
</script>

<style lang="css">
/* font-size: 20px;
 */
.size {
  font-size: 11px;
}
@media screen and (min-width: 320px) {
  .size-logo {
    font-size: calc(22px + 6 * ((100vw - 576px) / 680));
  }
  .size {
    font-size: calc(19px + 6 * ((100vw - 576px) / 680));
  }
  .otp-input {
    width: 30px;
    height: 30px;
    margin: 0 2px;
    font-size: 18px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .size-pass {
    font-size: 4vw;
  }
  .size-time {
    font-size: 5vw;
  }
}
@media screen and (min-width: 375px) {
  .size {
    font-size: calc(21px + 6 * ((100vw - 576px) / 680));
  }
  .size-logo {
    font-size: calc(23px + 6 * ((100vw - 576px) / 680));
  }
  .otp-input {
    width: 40px;
    height: 40px;
    margin: 0 2px;
    font-size: 20px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
}
@media screen and (min-width: 414px) {
  .size {
    font-size: calc(24px + 6 * ((100vw - 620px) / 680));
  }
  .size-logo {
    font-size: calc(26px + 6 * ((100vw - 576px) / 680));
  }
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    font-size: 20px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
}
@media screen and (min-width: 425px) {
  .size {
    font-size: calc(24px + 6 * ((100vw - 620px) / 680));
  }
  .size-logo {
    font-size: calc(26px + 6 * ((100vw - 576px) / 680));
  }
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    font-size: 20px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
}
@media screen and (min-width: 572px) {
  .size-time {
    font-size: 19px;
  }
  .size-pass {
    font-size: 17px;
  }
  .size-logo {
    font-size: calc(25px + 6 * ((100vw - 576px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  .size {
    font-size: calc(23px + 6 * ((100vw - 1174px) / 731));
  }
  .size-logo {
    font-size: calc(25px + 6 * ((100vw - 576px) / 680));
  }
  .otp-input {
    width: 55px;
    height: 55px;
    margin: 0 2px;
    font-size: 22px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
}

.containerimg {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered0 {
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered01 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box1 {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box2 {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box3 {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box4 {
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box5 {
  position: absolute;
  top: 79%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.centered-box8 {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box6 {
  position: absolute;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-box7 {
  position: absolute;
  top: 114%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  padding: 22px;
  width: 100%;
  max-width: 576px;
  background-color: #fff;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url(../../assets/wallstreet/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.img {
  width: 100%;
}
.ml-tarm {
  margin-left: 152px;
}
.tel-top {
  margin-top: 37px;
  margin-left: 108px;
  font-size: 15px;
}
.input-top {
  margin-top: 132px;
  margin-left: -20px;
  font-size: 15px;
}
.submit {
  margin-top: 112px;
  margin-left: 132px;
  font-size: 15px;
}
.rcorners {
  border-radius: 100%;
  background: #fff;
  padding: 0px;
  width: 57px;
  height: 57px;
  text-align: center;
}
@media only screen and (max-width: 320px) {
  .margin-span {
    margin-top: 58px;
  }
  .submit {
    margin-top: 69px;
    margin-left: 105px;
    font-size: 15px;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #7705bc;
  border-radius: 0px;
  outline: 0;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
  width: 50%;
}
.close-modal {
  position: absolute;
  color: #939393;
  font-size: 24px;
  cursor: pointer;
  right: 8px;
  top: 0px;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-top: 25px;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  margin-top: 80px;
}
.border-l {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 0px !important;
}
.border-r {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 6px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 6px !important;
}

.height-scoll {
  min-height: calc(100vh - 170px);
  max-height: calc(100vh - 170px);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* font-size: 14px; */
  text-align: left;
  padding: 20px;
}
.moc {
  width: 100%;
  height: 800px;
}
.btn-gray {
  border: none;
  background: #ccc;
  padding: 10px;
  border-radius: 25px;
}
/* width */
.height-scoll::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.height-scoll::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.height-scoll::-webkit-scrollbar-thumb {
  background: #0099d5;
}

/* Handle on hover */
.height-scoll::-webkit-scrollbar-thumb:hover {
  background: #0099d5;
}
</style>