<template>
  <div class="content">
    <div class="text-center" v-if="isLoading">
      <Loading />
    </div>
    <div class="container containerimg">
      <img
        src="@/assets/wallstreet/report_err.png"
        width="100%"
        class="mt-100"
      />
      <span class="centered1 size font-input" @click="submit()">ตกลง</span>
      <div class="container centered size font-input" style="color: #fff">
        ไม่พบข้อมูลของท่านในระบบ
        <br />
        กรุณาติดต่อเจ้าหน้าที่
        <br />
        หรือ โทร. 1211
      </div>
    </div>

    <div class="container col-12">
      <img
        class="img top-footer mt-100"
        src="@/assets/wallstreet/Footer.png"
        alt=""
        srcset=""
      />
    </div>
  </div>
</template>


<script>
import Modal from "../components/Modal/report.modal";
import Loading from "../components/Loading";
import { HTTP } from "../utility/http";
export default {
  components: {
    Modal,
    Loading,
  },
  data() {
    return { isLoading: true };
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    async goTo(link) {
      this.$router.push(link);
    },
    async closeliff() {
      //liff close
      // eslint-disable-next-line no-undef
      await liff.closeWindow();
    },
    async submit() {
      window.location.replace(process.env.VUE_APP_LIN);
    },
  },
};
</script>
<style scoped>
.mt-100 {
  margin-top: 100px;
}
.size {
  font-size: 16px;
}

/* .centered-box2 {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

@media screen and (min-width: 320px) {
  .size {
    font-size: calc(21px + 6 * ((100vw - 576px) / 680));
  }
  .otp-input {
    width: 30px;
    height: 30px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(300px);
    width: calc(250px);
    overflow: auto;
  }
}
@media screen and (min-width: 375px) {
  .size {
    font-size: calc(24px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 40px;
    height: 40px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(350px);
    width: calc(300px);
    overflow: auto;
  }
}
@media screen and (min-width: 425px) {
  .size {
    font-size: calc(26px + 6 * ((100vw - 480px) / 680));
  }

  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(400px);
    width: calc(324px);
    overflow: auto;
  }
}
@media screen and (min-width: 1000px) {
  .size {
    font-size: 35px;
  }
  .otp-input {
    width: 55px;
    height: 55px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .size-pass {
    font-size: "1rem";
  }
  .text-modal-terms {
    height: calc(446px);
    width: calc(400px);
    overflow: auto;
  }
}
.containerimg {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered1 {
  position: absolute;
  top: 91%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(17px + 6 * ((100vw - 320px) / 580));
}
.content {
  padding: 0px;
  width: 100%;
  max-width: 576px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url(../assets/wallstreet/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.mt-term {
  margin-top: -1000px;
}
.img {
  width: 100%;
}
.point-button {
  margin-left: 121px;
  margin-top: 120px;
}
.img {
  width: 100%;
}
.image-register {
  position: fixed;
  width: 100%;
  height: 886px;
  bottom: 0px;
  right: 0px;
  background-repeat: no-repeat;
}
.rcorners {
  border-radius: 25px;
  background: #73ad21;
  padding: 20px;
  width: 200px;
  height: 150px;
}
.botton {
  margin-top: -730px;
}
.input-bottom {
  margin-bottom: 0rem;
}
.top-modal {
  margin-top: -490px;
}
</style>