<template>
  <div class="content">
    <div class="containerimg">
      <img src="@/assets/wallstreet/connected04.png" width="87%" />
      <div
        class="container centered-box2 size font-input"
        style="color: #013359"
      >
        ขอบคุณที่ลงทะเบียนกับเรา
      </div>

      <div class="centered size-logo font-input" style="color: #fff">Thank You</div>
    </div>
  </div>
</template>
<script>
import Loading from "../components/Loading";
import { HTTP } from "../utility/http";

export default {
  components: {
    Loading,
  },
  data() {
    return { isLoading: true };
  },
  mounted() {
    localStorage.setItem("otp", true);
    this.isLoading = false;
    // this.submitFinal();
  },
  methods: {
    async closrliff() {
      //liff close
      // eslint-disable-next-line no-undef
      await liff.closeWindow();
    },
     async submitFinal() {
      const register = localStorage.getItem("payload_register");
      const JSONRegister = JSON.parse(register);
      if (JSONRegister.branch === `Alumni`) {
        const { data, status } = await HTTP.post(
          `api/register/alumni`,
          JSONRegister
        );
        if (status === 200) {
          if (data.status === "CREATE_SUCCESS") {
            // this.goTo(`/connected`);
          } else {
            this.error = data.status;
          }
        }
      } else {
        const { data, status } = await HTTP.post(
          `api/register/new`,
          JSONRegister
        );
        if (status === 200) {
          if (data.status === "CREATE_SUCCESS") {
            // this.goTo(`/connected`);
          } else {
            this.error = data.status;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.centered {
  position: absolute;
  top: 31%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.size {
  font-size: 16px;
}
.containerimg {
  position: relative;
  text-align: center;
  color: #000;
}
.content {
  padding: 0px;
  width: 100%;
  max-width: 576px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url(../assets/wallstreet/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.centered-box2 {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 520 !important;
}
@media screen and (min-width: 320px) {
  .size {
    font-size: calc(32px + 6 * ((100vw - 576px) / 680));
  }
  .size-logo {
    font-size: calc(48px + 6 * ((100vw - 576px) / 680));
  }
}
@media screen and (min-width: 375px) {
  .size {
    font-size: calc(36px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(54px + 6 * ((100vw - 576px) / 680));
  }
}
@media screen and (min-width: 414px) {
  .size {
    font-size: calc(38px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(60px + 6 * ((100vw - 576px) / 680));
  }
}
@media screen and (min-width: 425px) {
  .size {
    font-size: calc(38px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(60px + 6 * ((100vw - 576px) / 680));
  }
}
@media screen and (min-width: 768px) {
  .size {
    font-size: calc(50px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(60px + 6 * ((100vw - 576px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  .size {
    font-size: 53px;
  }
  .size-logo {
    font-size: calc(60px + 6 * ((100vw - 576px) / 680));
  }
}
</style>