<template>
  <div class="content">
    <div class="text-center" v-if="isLoading">
      <Loading />
    </div>

    <div class="col-12 containerimg">
      <img src="@/assets/wallstreet/ris04.png" width="100%" />

      <div class="centered size-b font-input">
        {{ $t("hi") }}{{ displayname }}
      </div>

      <div class="centered0 size-logo font-input">ลงทะเบียนเข้าสู่ระบบ</div>

      <div class="centered1 size font-input col-4">
        <img
          :src="this.$store.state.auth.line_profile.line_display_image"
          class="line_img"
          alt=""
        />
      </div>

      <div class="centered2 size font-input col-10" style="text-align: left">
        <span for="empid" style="color: #fff" class="font-input">ชื่อ</span>
        <input
          type="text"
          class="form-control rcorners2 font-input size"
          id="name"
          v-model="formData.name"
          placeholder="กรอกชื่อ"
          :class="{
            'is-invalid': formDirty && !formData.name,
            'is-valid': formDirty && formData.name,
          }"
        />
      </div>

      <div class="centered3 size font-input col-10" style="text-align: left">
        <span for="empid" style="color: #fff" class="font-input">นามสกุล</span>
        <input
          type="text"
          class="form-control rcorners2 font-input size"
          id="lastname"
          v-model="formData.lastname"
          placeholder="กรอกนามสกุล"
          :class="{
            'is-invalid': formDirty && !formData.lastname,
            'is-valid': formDirty && formData.lastname,
          }"
        />
      </div>

      <div class="centered5 size font-input col-10" style="text-align: left">
        <span for="empid" style="color: #fff" class="font-input"
          >เบอร์โทรศัพท์</span
        >
        <input
          type="tel"
          class="form-control rcorners2 font-input size"
          id="tel"
          v-model="formData.tel"
          placeholder="กรอกเบอร์โทรศัพท์"
          maxlength="10"
          @keypress="onlyNumbers"
          :class="{
            'is-invalid': formDirty && !formData.tel,
            'is-valid': formDirty && formData.tel,
          }"
        />
      </div>

      <div class="centered6 size font-input col-10" style="text-align: left">
        <span for="empid" style="color: #fff" class="font-input"
          ><span class="color-red"></span>อีเมล</span
        >

        <input
          v-model="email"
          class="form-control rcorners2 font-input size"
          placeholder="กรอกอีเมล"
          :class="{
            'is-invalid': formDirty && !email.match(/(.+)@(.+){2,}\.(.+){2,}/),
            'is-valid': formDirty && email,
          }"
        />
      </div>

      <div class="centered7 size font-input col-10" style="text-align: left">
        <span for="empid" style="color: #fff" class="font-input"
          ><span class="color-red"></span>สาขาที่เรียนอยู่ในปัจจุบัน</span
        >

        <div class="input-group mb-3">
          <select
            class="custom-select rcorners2 font-input size"
            v-model="inputclass"
            :class="{
              'is-invalid':
                formDirty && inputclass === `เลือกสาขาที่เรียนอยู่ในปัจจุบัน`,
              'is-valid': formDirty && inputclass,
            }"
          >
            <option selected>เลือกสาขาที่เรียนอยู่ในปัจจุบัน</option>

            <option
              v-if="newbrance === 'ยังไม่ได้เป็นนักเรียน'"
              style="color: red"
              @click="changName(newbrance)"
            >
              {{ newbrance }}
            </option>

            <option
              v-for="item in brance"
              :key="item.name"
              @click="changName(item.name)"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="centered8 size font-input col-10" style="text-align: left">
        <form class="was-validated">
          <div class="custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              class="custom-control-input rounded-lg"
              id="customControlValidation1"
              required
              v-model="agree"
              @click="checkModal()"
            />
            <label
              class="custom-control-label"
              style="color: #fff"
              for="customControlValidation1"
              >เงื่อนไขและข้อตกลง</label
            >
          </div>
        </form>
      </div>
      <div
        class="centered4 size font-input col-4 mt-1"
        style="color: #fff"
        @click="submitForm()"
      >
        <span>ถัดไป</span>
      </div>
    </div>
    <div class="container col-12">
      <img
        class="img"
        src="@/assets/wallstreet/Footer.png"
        style="margin-top: 55px"
        alt=""
        srcset=""
      />
    </div>
  </div>
</template>


<script>
import initMixin from "../mixins/init.mixin";
import Loading from "../components/Loading";
import { HTTP } from "../utility/http";

export default {
  mixins: [initMixin],
  components: {
    Loading,
  },

  data() {
    return {
      displayname: "",
      formData: {
        name: "",
        lastname: "",
        tel: "",
      },
      msg: [],
      email: "",
      pictureurl: "",
      statusMessage: "",
      userId: "",
      formDirty: false,
      phone_number: "",
      inputclass: "เลือกสาขาที่เรียนอยู่ในปัจจุบัน",
      newbrance: "",
      otp: "",
      errors: [],
      language: this.$i18n.locale,
      agree: true,
      isLoading: true,
      emailBlured: false,
      valid: false,
      data: "",
      checkPhone: "",
      checkgoTo: "",
      brance: [],
    };
  },
  // watch: {
  // email(value) {
  //   this.email = value;
  //   this.validateEmail(value);
  // },
  // },
  mounted() {
    $("#exampleModal").modal("show");
    this.runner();
    this.getAll();
    this.isLoading = false;
    const register = localStorage.getItem("register");
    const JSONProfile = JSON.parse(register);
    this.formData.name = JSONProfile.first_name ? JSONProfile.first_name : "";
    this.formData.lastname = JSONProfile.last_name ? JSONProfile.last_name : "";
    this.formData.tel = JSONProfile.phone_number
      ? JSONProfile.phone_number
      : "";
    this.email = JSONProfile.email ? JSONProfile.email : "";
  },
  methods: {
    async goTo(link) {
      this.$router.push(link);
    },
    async checkBox() {
      this.agree = !this.agree;
    },
    checkModal() {
      let payload = {
        line_user_id: this.$store.state.auth.line_profile.id,
        first_name: this.formData.name ? this.formData.name : "",
        last_name: this.formData.lastname ? this.formData.lastname : "",
        phone_number: this.formData.tel ? this.formData.tel : "",
        email: this.email ? this.email : "",
        branch: this.inputclass ? this.inputclass : "",
        status: "1",
        is_accept: "1",
      };
      localStorage.setItem("register", JSON.stringify(payload));

      this.agree = !this.agree;
      if (this.agree) {
        this.goTo(`/`);
      } else {
      }
    },
    checkInvalit() {
      if (
        this.employee_id === "" ||
        this.phone_number === "" ||
        this.otp === "" ||
        this.agree === false
      ) {
        return false;
      } else {
        return true;
      }
    },
    async runner() {
      // console.log("runner");
      const profile = await localStorage.getItem("LINE_LIFF_DATA");
      const JSONProfile = await JSON.parse(profile);
      (this.displayname = JSONProfile.displayName),
        (this.email = JSONProfile.email),
        (this.pictureurl = JSONProfile.pictureUrl),
        (this.statusMessage = JSONProfile.statusMessage),
        (this.userId = JSONProfile.userId);
    },
    async submitForm() {
      //api upload
      const checkPhone = {
        sort: "",
        order: "",
        limit: 30,
        offset: 0,
        is_export: false,
        filter: {
          start_date: "0001-01-01T00:00:00Z",
          end_date: "0001-01-01T00:00:00Z",
        },
      };

      let payload = {
        line_user_id: this.$store.state.auth.line_profile.id,
        first_name: this.formData.name,
        last_name: this.formData.lastname,
        phone_number: this.formData.tel,
        email: this.email,
        branch: this.inputclass,
        status: "1",
        is_accept: "1",
      };

      const { data, status } = await HTTP.post(
        `/api/register/check/payload`,
        payload
      );
      this.formDirty = true;
      if (
        !this.formData.tel ||
        !this.formData.lastname ||
        !this.formData.name ||
        !this.email.match(/(.+)@(.+){2,}\.(.+){2,}/) ||
        this.inputclass === "เลือกสาขาที่เรียนอยู่ในปัจจุบัน" ||
        !this.agree
      ) {
        this.$toast.error("Input required field");
      }
      this.isLoading = true;
      localStorage.setItem("payload_register", JSON.stringify(payload));
      if (status === 200) {
        if (
          this.inputclass === `Nonstudent` ||
          data.message === "Student No Branch"
        ) {
          const register = localStorage.getItem("payload_register");
          const JSONRegister = JSON.parse(register);
          let payload = {
            line_user_id: JSONRegister.line_user_id,
            first_name: JSONRegister.first_name,
            last_name: JSONRegister.last_name,
            phone_number: JSONRegister.phone_number,
            email: JSONRegister.email,
            branch: JSONRegister.branch,
            status: "2",
            is_accept: "1",
          };

          const { data, status } = await HTTP.post(
            `api/register/nocenter`,
            payload
          );

          if (status === 200) {
            if (data.status === "CREATE_SUCCESS") {
            } else {
              this.error = data.status;
            }
          }
          this.$router.push(`/report/number`);
          
        } else if (
          this.inputclass !== `Nonstudent` &&
          this.inputclass !== `Alumni`
        ) {
          this.$router.push(`/passsms`);
        } else if (this.inputclass === `Alumni`) {
          const register = localStorage.getItem("payload_register");
          const JSONRegister = JSON.parse(register);
          let payload = {
            line_user_id: JSONRegister.line_user_id,
            first_name: JSONRegister.first_name,
            last_name: JSONRegister.last_name,
            phone_number: JSONRegister.phone_number,
            email: JSONRegister.email,
            branch: JSONRegister.branch,
            status: "1",
            is_accept: "1",
          };
          localStorage.setItem("register", JSON.stringify(payload));

          // const { data, status } = await HTTP.post(
          //   `api/register/alumni`,
          //   payload
          // );

          // if (status === 200) {
          //   if (data.status === "CREATE_SUCCESS") {
          //   } else {
          //     this.error = data.status;
          //   }
          // }
          // this.$router.push(`/report`);
          this.$router.push(`/passsms`);
        }
      } else {
        if (this.inputclass === `Nonstudent`) {
          const register = localStorage.getItem("payload_register");
          const JSONRegister = JSON.parse(register);
          let payload = {
            line_user_id: JSONRegister.line_user_id,
            first_name: JSONRegister.first_name,
            last_name: JSONRegister.last_name,
            phone_number: JSONRegister.phone_number,
            email: JSONRegister.email,
            branch: JSONRegister.branch,
            status: "1",
            is_accept: "1",
          };

          // const { data, status } = await HTTP.post(
          //   `/api/register/non/student`,
          //   payload
          // );

          // if (status === 200) {
          //   if (data.status === "CREATE_SUCCESS") {
          //   } else {
          //     this.error = data.status;
          //   }
          // }

          this.$router.push(`/passsms`);
        } else if (
          this.inputclass !== `Nonstudent` &&
          this.inputclass !== `Alumni`
        ) {
          const register = localStorage.getItem("payload_register");
          const JSONRegister = JSON.parse(register);
          let payload = {
            line_user_id: JSONRegister.line_user_id,
            first_name: JSONRegister.first_name,
            last_name: JSONRegister.last_name,
            phone_number: JSONRegister.phone_number,
            email: JSONRegister.email,
            branch: JSONRegister.branch,
            status: "2",
            is_accept: "1",
          };

          const { data, status } = await HTTP.post(
            `api/register/incorrect`,
            payload
          );

          if (status === 200) {
            if (data.status === "CREATE_SUCCESS") {
            } else {
              this.error = data.status;
            }
          }

          this.$router.push(`/report/number`);
        } else if (this.inputclass === `Alumni`) {
          const register = localStorage.getItem("payload_register");
          const JSONRegister = JSON.parse(register);
          let payload = {
            line_user_id: JSONRegister.line_user_id,
            first_name: JSONRegister.first_name,
            last_name: JSONRegister.last_name,
            phone_number: JSONRegister.phone_number,
            email: JSONRegister.email,
            branch: JSONRegister.branch,
            status: "1",
            is_accept: "1",
          };

          localStorage.setItem("register", JSON.stringify(payload));

          // const { data, status } = await HTTP.post(
          //   `api/register/alumni`,
          //   payload
          // );

          // if (status === 200) {
          //   if (data.status === "CREATE_SUCCESS") {
          //   } else {
          //     this.error = data.status;
          //   }
          // }

          // this.$router.push(`/report`);
          this.$router.push(`/passsms`);
        }
      }
    },

    changName(value) {
      this.inputclass = value;
    },
    changagree() {
      this.agree = !this.agree;
      if (this.agree === true) {
        document.getElementById("exampleModal").style.display = "block";
      } else {
        document.getElementById("exampleModal").style.display = "none";
      }
    },

    validEmail: function (email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },

    onlyNumbers(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },

    async getAll() {
      const payload = {
        sort: "",
        order: "",
        limit: 20,
        offset: 0,
        is_export: false,
        filter: {
          start_date: "0001-01-01T00:00:00Z",
          end_date: "0001-01-01T00:00:00Z",
        },
      };
      //  /api/register/new
      const { data, status } = await HTTP.post(`/cms/api/branch/list`, payload);
      if (status === 200) {
        this.data = {
          ...data.rows,
        };

        for (let i = 0; i < data.rows.length; i++) {
          if ("ยังไม่ได้เป็นนักเรียน" === data.rows[i].name) {
            this.newbrance = data.rows[i].name;
          } else {
            this.brance.push({ name: this.data[i].name });
          }
        }
        
      }
    },
  },
};
</script>
<style scoped>
.fakecbox {
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  margin: 3px;
  margin-left: 4px;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  background-color: rgb(222, 222, 222);
  background: linear-gradient(
    to bottom,
    rgb(243, 243, 243) 0%,
    rgb(224, 224, 224) 40%,
    rgb(224, 224, 224) 100%
  );
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-top-color: rgb(178, 178, 178);
  border-left-color: rgb(167, 167, 167);
  border-right-color: rgb(167, 167, 167);
  border-bottom-color: rgb(167, 167, 167);
}
.fakecbox:hover {
  border-top-color: rgb(168, 168, 168);
  border-left-color: rgb(157, 157, 157);
  border-right-color: rgb(157, 157, 157);
  border-bottom-color: rgb(157, 157, 157);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.125);
  background: linear-gradient(
    to bottom,
    rgb(244, 244, 244) 0%,
    rgb(226, 226, 226) 40%,
    rgb(226, 226, 226) 100%
  );
}
.fakecbox:active {
  border-top-color: rgb(173, 173, 173);
  border-left-color: rgb(161, 161, 161);
  border-right-color: rgb(161, 161, 161);
  border-bottom-color: rgb(161, 161, 161);
  background: linear-gradient(
    to bottom,
    rgb(231, 231, 231) 0%,
    rgb(213, 213, 213) 40%,
    rgb(213, 213, 213) 100%
  );
  box-shadow: none;
}
.fakecbox.checked::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAM1BMVEX///9CQkJERERMTExPT09WVlZZWVlfX19gYGBlZWVmZmZpaWlra2txcXFycnJzc3N6enq1N2u5AAAAAXRSTlMAQObYZgAAAC5JREFUeAElwYcRACEMwDD7eyHA/tNyuUiUj3JtB+nXBp2pAx5PvYFQd9KrlCAtF1AAoT8ZlaoAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
}
.fakecbox.red {
  background: rgba(255, 0, 0, 0.4);
  border: 1px solid rgba(200, 0, 0, 0.5);
}
.fakecbox.redonuncheck.unchecked {
  background: rgba(255, 0, 0, 0.4);
  border: 1px solid rgba(200, 0, 0, 0.5);
}

/* input[type="checkbox"] {
  display: none;
}

label {
  border: 1px solid red;
  width: 15px;
  height: 15px;
  display: block;
}

input:checked + label {
  border-color: blue;
} */

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.45rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.modal-dialog {
  position: relative;
  width: auto;
  /* margin: .5rem; */
  pointer-events: none;
  margin-top: 80px;
}

.size {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .size {
    font-size: calc(17px + 6 * ((100vw - 320px) / 680));
  }
  .otp-input {
    width: 30px;
    height: 30px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(300px);
    width: calc(250px);
    overflow: auto;
  }
  .size-b {
    font-size: calc(21px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(33px + 6 * ((100vw - 480px) / 680));
  }
}
@media screen and (min-width: 375px) {
  .size-b {
    font-size: calc(23px + 6 * ((100vw - 480px) / 680));
  }
  .size {
    font-size: calc(20px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(34px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 40px;
    height: 40px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(350px);
    width: calc(300px);
    overflow: auto;
  }
}

@media screen and (min-width: 414px) {
  .size {
    font-size: calc(21px + 6 * ((100vw - 480px) / 680));
  }
  .size-b {
    font-size: calc(25px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(36px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 40px;
    height: 40px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(350px);
    width: calc(300px);
    overflow: auto;
  }
}
@media screen and (min-width: 425px) {
  .size {
    font-size: calc(22px + 6 * ((100vw - 480px) / 680));
  }
  .size-b {
    font-size: calc(25px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(36px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .text-modal-terms {
    height: calc(400px);
    width: calc(324px);
    overflow: auto;
  }
  .size-logo {
    font-size: calc(36px + 6 * ((100vw - 480px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  .size {
    font-size: 22px;
  }
  .size-b {
    font-size: calc(25px + 6 * ((100vw - 480px) / 680));
  }
  .size-logo {
    font-size: calc(36px + 6 * ((100vw - 480px) / 680));
  }
  .otp-input {
    width: 55px;
    height: 55px;
    margin: 0 2px;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid rgba(162, 11, 11, 0.3);
    text-align: center;
  }
  .size-pass {
    font-size: "1rem";
  }
  .text-modal-terms {
    height: calc(446px);
    width: calc(400px);
    overflow: auto;
  }
}
.containerimg {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered0 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered1 {
  position: absolute;
  top: 26%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}

.centered2 {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered3 {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered4 {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered5 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered6 {
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}
.centered7 {
  position: absolute;
  top: 74%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}

.centered8 {
  position: absolute;
  top: 81%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: calc(14px + 6 * ((100vw - 320px) / 580)); */
}

span:hover {
  color: #093359;
}
input:invalid {
  border-color: red;
}
/* .font-input { */
/* font-family: "Kanit", sans-serif; */
/* font-size: 14px; */
/* } */
.modal-center {
  /* position: relative; */
  display: -ms-flexbox;
  /* display: flex; */
  /* -ms-flex-direction: column; */
  /* flex-direction: column; */
  width: 100%;
  /* pointer-events: auto; */
  /* background-color: #fff; */
  /* background-clip: padding-box; */
  /* border: 1px solid rgba(0,0,0,.2); */
  border-radius: 0.3rem;
  outline: 0;
}
.content {
  padding: 0px;
  width: 100%;
  max-width: 575px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url(../assets/wallstreet/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.point-button {
  margin-left: 117px;
  margin-top: 150px;
}
.img {
  width: 100%;
}
.image-register {
  position: fixed;
  width: 100%;
  height: 1122px;
  bottom: 0px;
  right: 0px;
  background-repeat: no-repeat;
}
.rcorners {
  border-radius: 25px;
  background: #73ad21;
  padding: 20px;
  width: 200px;
  height: 150px;
}
.botton {
  margin-top: -730px;
}
.input-bottom {
  margin-bottom: 0rem;
}
/* } */

@media only screen and (max-width: 320px) {
  .botton {
    margin-top: -630px;
  }
  .input-bottom {
    margin-bottom: 0rem;
  }
  .point-button {
    margin-left: 94px;
    margin-top: 74px;
  }
}
/* The container */
.containers {
  position: relative;
  padding-left: 35px;
  width: fit-content;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 9px;
  margin: 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ccd0d1;
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.containers:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containers input:checked ~ .checkmark {
  background: #fff;
  border: 1px solid;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containers input:checked ~ .checkmark:after {
  display: block;
  /* border: 1px solid; */
}

/* Style the checkmark/indicator */
.containers .checkmark:after {
  left: 7px;
  top: -3px;
  width: 9px;
  height: 18px;
  border: solid #28a745;
  border-width: 0px 4px 4px 0px;
  transform: rotate(45deg);
}
</style>