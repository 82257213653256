const initMixin = {
    methods: {
        async _onInit() {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async resolve => {
                const response = {status: -1};
                const { line_profile } = this.$store.state.auth;
                // const { line_profile, user_profile } = this.$store.state.auth;
                const user = this.$store.state.auth.user_profile.line_user_id
                // console.log(user_profile)
                if (line_profile.id !== "") {
                    // Note: Login Already
                    if (user !== "") {
                        // Note: Success Bypass
                        response.status = 1;
                    } else {
                        // Note: No Profile > Set Profile
                        response.status = 0;
                    }
                }
                resolve(response);
            })
        }
    }
};

export default initMixin

