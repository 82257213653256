<template>
  <div>
    <div class="text-center" v-if="isLoading">
      <Loading />
    </div>
    <Pass />
  </div>
</template>

<script>
import Pass from "../components/Modal/passsms";
import { HTTP } from "../utility/http";
import Loading from "../components/Loading";
export default {
  components: {
    Pass,
    Loading,
  },
  data() {
    return {
      pass: "",
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
    },
    check() {},

    async closrliff() {
      //liff close
      // eslint-disable-next-line no-undef
      await liff.closeWindow();
    },
    // async submitForm() {
    //   this.isLoading = true;
    //   const { data } = await HTTP.get(
    //     `/api/register/detail/` + this.$store.state.auth.line_profile.id
    //   );
    //   if (this.pass === data.data.member_id) {
    //     this.goTo(`/connected`);
    //   } else {
    //     this.error = data.status;
    //   }
    // },
  },
};
</script>

<style scoped>
.top-footer {
  margin-top: 200px;
}
.image-register {
  position: fixed;
  width: 100%;
  height: 810px;
  bottom: 0px;
  right: 0px;
  background-repeat: no-repeat;
}
.mt-pass {
  margin-top: -425px;
}
.img {
  width: 100%;
}
@media only screen and (max-width: 320px) {
  .top-footer {
    margin-top: -925px;
  }
  .image-register {
    position: fixed;
    width: 100%;
    height: 810px;
    bottom: 0px;
    right: 0px;
    background-repeat: no-repeat;
  }
  .mt-pass {
    margin-top: -364px;
  }
}
</style>