import { render, staticRenderFns } from "./ErrReport.vue?vue&type=template&id=4e6c3e60&scoped=true&"
import script from "./ErrReport.vue?vue&type=script&lang=js&"
export * from "./ErrReport.vue?vue&type=script&lang=js&"
import style0 from "./ErrReport.vue?vue&type=style&index=0&id=4e6c3e60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e6c3e60",
  null
  
)

export default component.exports