<template>
  <div>
    <div class="modal-body">
      <div
        class="text-terms font-14 text-modal-terms font-input"
        v-html="name"
        style="color: #fff"
      ></div>
    </div>
    <br />
    <br />
    <div
      class="submit-terms ml-tarm font-input"
      style="color: #fff"
      @click="goTo(`/`)"
    >
      <span class="span-padding" data-dismiss="modal">ปิด</span>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../utility/http";
export default {
  props: ["tab"],
  components: {},
  data() {
    return {
      is_tab: true,
      d: "<",
      data: "",
      name: "ไม่พบข้อมูลของท่านกรุณาติดต่อเจ้าหน้าที่",
    };
  },
  mounted() {
    this.getAll();
    if (this.tab === 1) {
      this.is_tab = false;
    } else {
      this.is_tab = true;
    }
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
    },
    changeTab() {
      this.is_tab = !this.is_tab;
    },
    async getAll() {
      // const register = localStorage.getItem("payload_register");
      const JSONRegister = {
        sort: "",
        order: "",
        limit: 10,
        offset: 0,
        is_export: false,
        filter: {
          start_date: "0001-01-01T00:00:00Z",
          end_date: "0001-01-01T00:00:00Z",
        },
      };
      //  /api/register/new
      const { data, status } = await HTTP.post(
        `/cms/api/term-and-condition/list`,
        JSONRegister
      );
      if (status === 200) {
        this.data = {
          ...data.rows[0],
        };
      }
    },
  },
};
</script>
<style scoped>
/* span:link {
  color: green;
} */
span:hover {
  color: #093359;
}
.font-input {
  font-family: "Kanit", sans-serif;
  font-size: 14px;
}
.ml-tarm {
  margin-left: 145px;
  margin-top: 0px;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #7705bc;
  border-radius: 0px;
  outline: 0;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
  width: 50%;
}
.close-modal {
  position: absolute;
  color: #939393;
  font-size: 24px;
  cursor: pointer;
  right: 8px;
  top: 0px;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-top: 25px;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  margin-top: 80px;
}
.border-l {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 0px !important;
}
.border-r {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 6px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 6px !important;
}

.height-scoll {
  min-height: calc(100vh - 170px);
  max-height: calc(100vh - 170px);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* font-size: 14px; */
  text-align: left;
  padding: 20px;
}
.moc {
  width: 100%;
  height: 800px;
}
.btn-gray {
  border: none;
  background: #ccc;
  padding: 10px;
  border-radius: 25px;
}
/* width */
.height-scoll::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.height-scoll::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.height-scoll::-webkit-scrollbar-thumb {
  background: #0099d5;
}

/* Handle on hover */
.height-scoll::-webkit-scrollbar-thumb:hover {
  background: #0099d5;
}
</style>